.servicesMenuItem {
    display: flex;
    flex-direction: column;
    width: 148px;
    height: 148px;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    background: linear-gradient(180deg, #EFEFEF 0%, #E0E0E0 100%);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    gap: 8px;
    margin-bottom: 30px;
}

.img {
    width: 90px;
    height: 90px;
    margin-top: -14px
}

.title {
    color: #000;
    font-size: 14px;
    text-align: center;
}