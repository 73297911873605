.listServicesPage {
    display: flex;
    width: 100%;
    cursor: pointer;
}

.styleWrapper {
    display: flex;
    flex-grow: 1;
    padding: 16px;
    margin-bottom: 16px;
}