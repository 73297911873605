.courierServicesPage {
    display: flex;
    width: 100%;
    padding: 24px;
}

.styleWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 1px solid #C5C5C5;
    border-radius: 4px;
    align-items: center;
    padding: 0px 16px 16px 16px;
}

.label {
    background-color: var(--main-bg-color);
    margin-top: -12px;
    padding-right: 16px;
    padding-left: 16px;
    font-family: Roboto Flex;
    font-size: 18px;
    color: #000;
}

.icon {
    width: 64px;
    height: 64px;
    margin-top: 16px;
}

.description {
    color: #000;
    text-align: center;
    font-family: Roboto Flex;
    font-size: 20px;
    white-space: break-spaces;
    margin-bottom: 10px;
}

.price {
    color: #fff;
    background-color: #20C392;
    border-radius: 4px;
    border: none;
    padding: 6px 12px 6px 12px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    margin-top: 14px;
    margin-bottom: 22px;
}

.listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.point {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    border-radius: 2px;
    background-color: #20C392;
    margin-right: 16px;
}

.listLabel {
    color: #000;
    font-family: Roboto Flex;
    font-size: 16px;
}

.button {
    display: flex;
    color: #000;
    font-family: Roboto Flex;
    font-size: 22px;
    height: 80px;
    border-radius: 4px;
    background: linear-gradient(180deg, #FFE489 0%, #FFBF5D 100%);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    border: none;
    cursor: pointer;
}

.buttonStyleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-grow: 1;
}

.buttonStyleWrapper:active {
    background-color: rgba(0, 0, 0, 0.10)
}