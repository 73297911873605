.receptionCentersPage {
    display: flex;
    flex-grow: 1;
    padding: 16px;
}

.styleWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 56px;
    margin-bottom: 16px;
}