.listServices {
    border-radius: 4px;
    border: 1px solid #C5C5C5;
    padding: 4px 30px 4px 30px;
    margin-bottom: 16px;
    flex-grow: 1;
}

.listItem {
    border-bottom: 1px solid #C5C5C5;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: center;
    gap: 2px;
}

.listItem:last-child {
    border: none;
}

.title {
    color: #1A7D5A;
    font-size: 18px;
    font-weight: 700;
}

.priceBlock {
    display: flex;
    color: #000;
    font-size: 18px;
}

.price {
    font-weight: 700;
}