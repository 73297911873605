.workingDaysSchedule {
    display: grid;
    white-space: nowrap;
    grid-template-columns: min-content min-content;
    grid-column-gap: 16px;
    color: #000;
    font-size: 15px;
    font-weight: 600;
}

.firstColumn {
    grid-column-start: 1;
    grid-column-end: 1;
}

.secondColumn {
    grid-column-start: 2;
    grid-column-end: 2;
}