.workingDaysScheme {
    display: flex;
    gap: 4px;
}

.dayStatus {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    border-radius: 2px;
}

.fullDay {
    background: #20C392;
}

.nonWorkingDay {
    background: #FB5151;
}

.halfDay {
    background: #FFBF5D;
}