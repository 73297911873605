.breadCrumbs {
    color: #000;
}

.firstPart {
    font-size: 18px;
    font-weight: 700;
}

.secondPart {
    font-size: 18px;
    font-weight: 400;
}