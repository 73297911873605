.app {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}

.scrollBar {
    display: flex;
    height: 100%;
}