.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 16px 8px 16px;
}

.headerContainer {
    display: grid;
    min-height: 148px;
    grid-template-columns: 1fr 116px 1fr;
}

.leftColunnContainer {
    grid-column-start: 1;
    grid-column-end: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
}

.centerColunnContainer {
    grid-column-start: 2;
    grid-column-end: 2;
    justify-content: center;
    align-items: center;
    display: flex;
}

.rightColunnContainer {
    grid-column-start: 3;
    grid-column-end: 3;
    justify-content: center;
    align-items: center;
    display: flex;
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E1E1E1;
    width: 116px;
    height: 116px;
    border-radius: 50%;
    background-color: #ffffff;
    cursor: pointer;
}

.backButtonContainer,
.homePageButtonContainer {
    width: 58px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.controlButtons {
    width: 46px;
    height: 46px;
    background-color: #D9D9D9;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.backButton {
    padding-right: 6px;
}

.homePageButton {
    padding-top: 2px;
}

.backButton:active,
.homePageButton:active {
    background-color: #c0bdbd;
    border: none;
}

.backButtonLabel,
.homePageButtonLabel {
    color: #8E95A6;
    font-family: Roboto Flex;
    font-size: 16px;
    white-space: nowrap;
}