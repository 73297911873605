:root {
  --main-bg-color: #F7F7F7;
}

@font-face {
  font-family: "Roboto Flex";
  src: url("../public/fonts/RobotoFlex-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

#root {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Roboto Flex";
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::-webkit-scrollbar {
  width: 0px;
}

body {
  background-color: var(--main-bg-color);
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
}