.simpleMenuItem {
    display: flex;
    height: 86px;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    background: linear-gradient(180deg, #EFEFEF 0%, #E0E0E0 100%);
}

.styleWrapper:active {
    background-color: rgba(0, 0, 0, 0.10)
}

.styleWrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    padding-left: 26px;
    padding-right: 16px;
}

.menuInformation {
    display: flex;
    flex-direction: column;
}

.menuLabel {
    color: #000000;
    font-size: 22px;
}

.menuDescription {
    color: #949494;
    font-size: 14px;
    min-height: 16px;
}

.menuImage {
    width: 64px;
    height: 64px;
}