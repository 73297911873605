.receptionCentersMenu {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border: 1px solid #C5C5C5;
    border-radius: 4px;
    width: 100%;
}

.address {
    color: #000000;
    font-size: 18px;
    line-height: 21.09px;
    margin-bottom: 2px;
}

.additionalServices {
    border-radius: 4px;
    background: #FFC463;
    display: flex;
    flex-direction: column;
    width: min-content;
    white-space: nowrap;
    padding: 2px 8px 2px 10px;
    color: #B47000;
    font-size: 14px;
    text-transform: lowercase;
}

.workDays {
    display: flex;
    gap: 2px;
    flex-direction: column;
    margin-bottom: 26px;
    margin-top: 28px;
}

.contactNumbers {
    color: #20C392;
    font-size: 22px;
    font-weight: 600;
    line-height: 25.78px;
    margin-bottom: 24px;
}

.contactNumber {
    cursor: pointer;
}

.mapButton {
    height: 72px;
    display: flex;
    border-radius: 4px;
    background: linear-gradient(180deg, #EFEFEF 0%, #E0E0E0 100%);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    border: none;
    cursor: pointer;
}

.mapButtonStyleWrapper {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 22px;
    height: 100%;
}

.mapButtonStyleWrapper:active {
    background-color: rgba(0, 0, 0, 0.10);
}