.servicesMenuPage {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    padding: 16px
}

.sectionGrid {
    display: flex;
    justify-content: center;
    align-items: center;
}